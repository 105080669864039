import React from "react"
import { graphql, useStaticQuery } from "gatsby"
// import { graphql, useStaticQuery, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/UI/Layout"
import Navbar from "../../components/UI/Navbar"
import Seo from "../../components/Seo"
import ServicePage from "../../components/templates/ServicePage"

const query = graphql`
  {
    allContentfulInstallationImages(
      filter: {
        metadata: {
          tags: { elemMatch: { contentful_id: { eq: "bogsWetlandFilters" } } }
        }
      }
    ) {
      nodes {
        id
        image {
          gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
          id
          title
        }
      }
    }
  }
`

export default function BogsWetlandFiltersPage() {
  const title = `Bogs & Wetland Filter` // Singular (not plural)
  const {
    allContentfulInstallationImages: { nodes: image },
  } = useStaticQuery(query)

  const imageList = image[0].image
// const link = <a href="/new-installations/koi-ponds/">koi ponds</a>
  return (
    <Layout>
      <Navbar />
      <Seo
        title={`${title}s`}
        description={`We help install ${title}s to improve your pond water quality and create a healthy ecosystem in your Oregon backyard.`}
      />

      <ServicePage
        title={title}
        imageList={imageList}
        bogsSingleImage
        img={
          <StaticImage
            src="../../assets/images/Pond-Bog_Andreatta-Waterscapes_Medford-Oregon.jpg"
            loading="eager"
            quality={90}
            style={{
              position: "absolute",
              width: "100%",
              zIndex: 1,
              height: "21rem",
            }}
            objectFit="cover"
            objectPosition="50% 25%"
            alt="Pondless Waterfall Medford Oregon"
          />
        }
        tagline={`Beautify your water feature with natural filtration`}
        body={[
          {
            id: 0,
            type: "paragraph",
            content: `A bog or wetland filter re-creates natures filter system. Bogs use aquatic plants and substrates that remove toxins, as well as allowing for colonization of beneficial bacteria. The plants and beneficial bacteria work together to strip away excess nutrients that inhibit algae growth. This improves your ponds water quality while also providing a beautiful filtering system that blends into your landscape.`,
          },
          {
            id: 1,
            type: "paragraph",
            content: `While a wetland filter is the key technical component for our <a href="/new-installations/recreation-ponds/" style="color: #da7235">recreational ponds</a>, we also frequently use pond bog filters on <a href="/new-installations/goldfish-ponds/" style="color: #da7235">goldfish</a> and <a href="/new-installations/koi-ponds/" style="color: #da7235">koi ponds</a> as well. There is a large variety of bog plants for ponds and having a pond bog filter provides a perfect place for water garden enthusiasts to grow their favorite aquatic plants. This also creates a healthy ecosystem for a multitude of wildlife to thrive and grow.`,
          },
        ]}
      />
    </Layout>
  )
}
